import {
         IonContent,
         IonPage,
         IonInput,
         IonIcon,
         IonItem,
         IonAlert,
         IonButton
       } from '@ionic/react';
import React from 'react';

import { eye, eyeOff, helpCircleOutline } from 'ionicons/icons'
import './Landing.css';
import firebase, {auth, db} from '../firebase'

type MyProps = {
  history: any;
  location: any;
}

// ### State definition ###

type MyState = {
  login: boolean; //controls whether login or sign in fields are shown
  email: string; //this will be the users email
  password: string; //this is the users password
  setpassword: string; //used when creating the users password
  passwordType: 'password' |'text'; //lets the user see their password
  showPassword: boolean; //controls the password view
  iconName: any; //icon to show for password view
  color: string; //color of password icon
  passwordConfirm: string; //this is the users confirm password
  passwordTypeConfirm: 'password' |'text'; //lets the user see their confirm password
  showPasswordConfirm: boolean; //controls the confirm password view
  iconNameConfirm: any; //icon to show for confirm password view
  colorConfirm: string; //color of vonfirm password icon
  submitPressed: string; //controls the background color of submit button
  username: string;
  showAlert: boolean;
}

class Landing extends React.Component<MyProps, MyState> {

  state: MyState = {
    login: true,
    email : '',
    password: '',
    setpassword: '',
    passwordType: 'password',
    showPassword: false,
    iconName: eye,
    color: 'medium',
    submitPressed: '#99fdff',
    passwordConfirm: '',
    passwordTypeConfirm: 'password',
    showPasswordConfirm: false,
    iconNameConfirm: eye,
    colorConfirm: 'medium',
    username: '',
    showAlert: false
  };




  constructor(props: MyProps) {
    super(props)

    // ### Begin Function Bindings ###
    this.togglePassword = this.togglePassword.bind(this);
    this.togglePasswordConfirm = this.togglePasswordConfirm.bind(this);
    this.submit = this.submit.bind(this);

    // ### End Function Bindings
  }


  // ### function to switch information that displays on screen and
  // to swap boolean ToggleView relies on ###

  togglePassword() {
    if(this.state.showPassword) {
      this.setState({
          showPassword: !this.state.showPassword,
          iconName: eye,
          passwordType: 'password',
          color: 'medium'
        });
    }
    else {
      this.setState({
          showPassword: !this.state.showPassword,
          iconName: eyeOff,
          passwordType: 'text',
          color: 'light'
        });
      }
  }

  togglePasswordConfirm() {
    if(this.state.showPasswordConfirm) {
      this.setState({
          showPasswordConfirm: !this.state.showPasswordConfirm,
          iconNameConfirm: eye,
          passwordTypeConfirm: 'password',
          colorConfirm: 'medium'
        });
    }
    else {
      this.setState({
          showPasswordConfirm: !this.state.showPasswordConfirm,
          iconNameConfirm: eyeOff,
          passwordTypeConfirm: 'text',
          colorConfirm: 'light'
        });
      }
  }

  submit() {
    //this function can both register and log-in a user
    if(this.state.login) {

      //user is attempting to login
      //check login credentials to ensure they are valid
      //checking for @ . in email
      //checking that password >= 8 chars
      let guard = true;
      if(!this.state.email.includes('@')) {
        guard = false;
      }
      if(!this.state.email.includes('.')) {
        guard = false;
      }
      if(this.state.password.length < 8) {
        guard = false;
      }

      if(guard) {
        //user passed initial credential check
        //attempt login to firebase
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
          auth.signInWithEmailAndPassword(this.state.email, this.state.password).catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
          }).then((result) => {
            if(result) {
              this.props.history.push('/shareplay')
            }
          })
        })

      }
    }
    else {
      //user is attempting to register
      let guard = true;
      if(this.state.setpassword.length < 8) {
        guard = false;
        console.log('password less than 8 characters')
      }
      if(this.state.setpassword !== this.state.passwordConfirm) {
        guard = false;
        console.log('passwords do not match')
      }
      if(this.state.username === '') {
        guard = false;
        console.log('did not set username')
      }
      if(guard) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
          auth.createUserWithEmailAndPassword(this.state.email, this.state.setpassword).catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
          }).then((result) => {
            if(result) {
              db.collection('users').doc(this.state.email).set({
                inShareplay: false,
                shareplayCode: '',
                username: this.state.username
              })
              db.collection('tokens').doc(this.state.email).set({
                refresh_token: '',
                access_token: ''
              }).then(() => {
                this.props.history.push('/shareplay')
              })

            }
          })
        })

      }

    }
  }


  // ### Render
  render() {
    return (
        <IonPage>
          <IonContent forceOverscroll={false} className='landingBackground'>

          <div className='toggleFields'>
            <IonButton
              fill='clear'
              onClick={() => {this.setState({login: true})}}
              style={{
                color: this.state.login === false ? '#999999' : '#ffffff',
                textDecoration: this.state.login === false ? 'none' : 'underline'
              }}
              className='loginHeader'>
                <b>Login</b>
            </IonButton>
            <IonButton
              fill='clear'
              onClick={() => {this.setState({login: false})}}
              style={{
                color: this.state.login === true ? '#999999' : '#ffffff',
                textDecoration: this.state.login === true ? 'none' : 'underline'
              }}
              className='registerHeader'>
                <b>Sign Up</b>
            </IonButton>
          </div>

          {this.state.login ? <div className='appLogo'>
            <h1 className='appLogoText'>Shareplay</h1>
            <h2 className='appLogoBottomText'>Share Music. Play Together.</h2>
          </div> : <div></div>}

          <div className='fieldsContainer'>
            {this.state.login ?
              <div>
                <div className='emailContainer'>
                <IonItem lines='none' className='emailItem'>
                  <IonInput
                    type='email'
                    clearOnEdit={false}
                    onIonChange={(e) => {this.setState({email: (e.target as HTMLInputElement).value})}}
                    className='inputField'
                    placeholder='Email Address'/>
                </IonItem>
                </div>
                <div className='passwordContainer'>
                  <IonItem lines='none' className='passwordItem'>
                    <IonInput
                      clearOnEdit={false}
                      onIonChange={(event) => this.setState({password: ((event.target as HTMLInputElement).value)})}
                      name='password'
                      placeholder='Password'
                      type = {this.state.passwordType}/>
                    <IonIcon
                      onClick={this.togglePassword}
                      slot='end'
                      color={this.state.color}
                      icon={this.state.iconName}/>
                  </IonItem>
                </div>
              </div>
            :
              <div>
              <IonAlert
                isOpen={this.state.showAlert}
                onDidDismiss={() => this.setState({showAlert: false})}
                message={'You can change your username at any point from the profile page.'}
                buttons={['Gotcha']}
              />
                <div className='emailContainer'>
                <IonItem lines='none' className='emailItem'>
                  <IonInput
                    type='email'
                    clearOnEdit={false}
                    onIonChange={(e) => {this.setState({email: (e.target as HTMLInputElement).value})}}
                    className='inputField'
                    placeholder='Email Address'/>
                </IonItem>
                </div>
                <div className='passwordContainer'>
                  <IonItem lines='none' className='passwordItem'>
                    <IonInput
                      clearOnEdit={false}
                      onIonChange={(event) => this.setState({setpassword: ((event.target as HTMLInputElement).value)})}
                      name='password'
                      placeholder='Password'
                      type = {this.state.passwordType}/>
                    <IonIcon
                      onClick={this.togglePassword}
                      slot='end'
                      color={this.state.color}
                      icon={this.state.iconName}/>
                  </IonItem>
                </div>
                <div className='passwordContainer'>
                  <IonItem
                    lines='none'
                    className='passwordItem'>
                    <IonInput
                      clearOnEdit={false}
                      onIonChange={(event) => this.setState({passwordConfirm: ((event.target as HTMLInputElement).value)})}
                      name='password'
                      placeholder='Confirm Password'
                      type = {this.state.passwordTypeConfirm}/>
                    <IonIcon
                      onClick={this.togglePasswordConfirm}
                      slot='end'
                      color={this.state.colorConfirm}
                      icon={this.state.iconNameConfirm}/>
                  </IonItem>
                </div>
                <div className='usernameContainerLanding'>
                  <IonItem
                    lines='none'
                    className='usernameItemLanding'>

                    <IonInput
                      clearOnEdit={false}
                      onIonChange={(event) => this.setState({username: ((event.target as HTMLInputElement).value)})}
                      type='text'
                      placeholder='Username'/>

                    <IonIcon
                      className="helpIcon"
                      onClick={() => this.setState({showAlert: true})}
                      slot='end'
                      icon={helpCircleOutline}/>
                  </IonItem>
                </div>

              </div>
            }
            <IonButton
              fill='clear'
              onClick={this.submit}
              onTouchEnd={()=>{this.setState({submitPressed: '#99fdff'})}}
              onTouchStart={() => {this.setState({submitPressed: '#7f7880'})}}
              style={{backgroundColor: this.state.submitPressed}}
              className='submitButton'>
              Submit
            </IonButton>

          </div>

          </IonContent>
        </IonPage>
      )
  }
}

export default Landing;
