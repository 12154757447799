import React from 'react'
import {
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonAvatar,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonToast
} from '@ionic/react';

import firebase, {db} from '../firebase';

import {headset, alertOutline} from 'ionicons/icons';
import './MemberModal.css'
import ProfilePicture from '../assets/spacepicture.jpg'

type MyProps = {
  isMemberModalOpen: boolean,
  toggleMemberModal: any,
  memberMap: any
}

type Member = {
  email: string,
  access_token: string,
  refresh_token: string,
  profilePicture: string,
  username: string,
  uid: string,
  owner: boolean
}

type MyState = {

}

class MemberModal extends React.Component<MyProps, MyState> {
  interval: any;
  realtime_database = firebase.database();
  constructor(props: MyProps) {
    super(props);
    this.state = {

    }
  }


  render() {
    return (
      <IonModal isOpen={this.props.isMemberModalOpen}>
      <IonHeader>
        <IonToolbar className='memberToolbarBackground'>
        <IonButtons slot='start'>
          <IonButton slot='start' fill='clear' onClick={() => {this.props.toggleMemberModal()}} className='memberClose'>
            Close
          </IonButton>
        </IonButtons>

          <IonTitle className='memberTitle'>
            Members
          </IonTitle>
        </IonToolbar>
      </IonHeader>
        <IonContent className='memberContentBackground'>
          <IonList className='membersParent'>
            {
              [...this.props.memberMap.values()].map((member : Member, index) => {
              return (
                <IonItem key={member.uid} lines='none'  className='memberBox'>
                  <IonAvatar slot='start' className='profPicture'>
                    <img src={ProfilePicture} />
                  </IonAvatar>
                  <IonLabel className='profName'>{member.username}</IonLabel>
                  {member.owner && <IonIcon className='ownerIcon' slot='end' icon={headset} />}
                </IonItem>
              )
            })
          }
          </IonList>
        </IonContent>
      </IonModal>
    )
  }
}

export default MemberModal
