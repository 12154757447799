import React from 'react'
import {
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonAvatar,
  IonModal,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonToast
} from '@ionic/react';

import firebase, {db, auth} from '../firebase';

import {headset, alertOutline} from 'ionicons/icons';
import './CreateShareplay.css'
import ProfilePicture from '../assets/spacepicture.jpg'
import MemberModal from '../components/MemberModal'
type MyProps = {
  email: string,
  shareplayCode: string,
  isMemberModalOpen: boolean,
  isHelpModalOpen: boolean,
  toggleMemberModal: any,
  updateNumMembers: any,
  toggleHelpModal: any
}

type Member = {
  email: string,
  access_token: string,
  refresh_token: string,
  profilePicture: string,
  username: string,
  uid: string
}

type MyState = {
  shareplayName: string;
  shareplayMembers: any[];
  subscriptionTracker: any[];
  ownerAccessToken: string;
  owner: string;
  songName: string;
  albumArt: string;
  artists: any;
  ownerRefreshToken: string;
  broadcaster: string;
  playHeld: boolean;
  pauseHeld: boolean;
  isToastOpen: boolean;
  fakeContext: string[];
  shouldUseFakeContext: boolean;
  memberMap: Map<string, Member>;
  unsubcribeFromShareplay: any;
  memberSubscription: any[];
}

class CreateShareplay extends React.Component<MyProps, MyState> {
  interval: any;
  realtime_database = firebase.database();
  constructor(props: MyProps) {
    super(props);
    this.state = {
      shareplayName: '',
      shareplayMembers: [],
      subscriptionTracker: [],
      ownerAccessToken: '',
      owner: '',
      songName: '',
      albumArt: '',
      artists: undefined,
      ownerRefreshToken: '',
      broadcaster: '',
      playHeld: false,
      pauseHeld: false,
      isToastOpen: false,
      fakeContext: [],
      shouldUseFakeContext: false,
      memberSubscription: [],
      memberMap: new Map(),
      unsubcribeFromShareplay: undefined
    }

    this.updateMembers = this.updateMembers.bind(this);
    this.getUsersCurrentTrack = this.getUsersCurrentTrack.bind(this);
    this.syncPlay = this.syncPlay.bind(this);
    this.syncPause = this.syncPause.bind(this);
    this.setUsersSong = this.setUsersSong.bind(this);
    this.userInterval = this.userInterval.bind(this);
    this.broadcasterInterval = this.broadcasterInterval.bind(this);
    this.readUri = this.readUri.bind(this);
    this.getAlbumSongs = this.getAlbumSongs.bind(this);
    this.getArtistSongs = this.getArtistSongs.bind(this);
    this.getPlaylistSongs = this.getPlaylistSongs.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => {this.userInterval()}, 1000)
    this.buildMembers()
    this.buildShareplay()
  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }

  userInterval() {
    if(this.state.broadcaster === auth.currentUser.uid) {
      this.broadcasterInterval()
    }
  }

  buildShareplay() {
    this.realtime_database.ref(this.props.shareplayCode).on('value', (shareplaySnapshot => {
      if(shareplaySnapshot.val() === null) {
        this.realtime_database.ref(this.props.shareplayCode).off()
      } else {
        this.setState({
          shareplayName: shareplaySnapshot.val().shareplayName,
          ownerAccessToken: shareplaySnapshot.val().ownerAccessToken,
          owner: shareplaySnapshot.val().owner,
          ownerRefreshToken: shareplaySnapshot.val().ownerRefreshToken,
          songName: shareplaySnapshot.val().songName,
          albumArt: shareplaySnapshot.val().albumArt,
          artists: shareplaySnapshot.val().artists,
          broadcaster: shareplaySnapshot.val().broadcaster
        })
        if(shareplaySnapshot.val().songName !== this.state.songName && shareplaySnapshot.val().artists !== this.state.artists) {
          this.syncPlay(this.state.ownerAccessToken)
        }
      }
    }))
  }

  buildMembers() {
    //Connects to the firebase realtime database and retrieves the information necessary to display members in the Shareplay
    // create subscriptions to possible changes / additions / deletions of data
    let realtimeChildAdded = this.realtime_database.ref(this.props.shareplayCode + '/members').on('child_added', (snapshot) => {
      console.log('Realtime database child added')
      console.log(snapshot.val())
      let updatedMemberMap = this.state.memberMap
      let newMember = {
        email: snapshot.val().email,
        uid: snapshot.val().uid,
        username: snapshot.val().username,
        profilePicture: snapshot.val().profilePicture,
        access_token: snapshot.val().access_token,
        refresh_token: snapshot.val().refresh_token,
        owner: snapshot.val().uid === auth.currentUser.uid,
      }
      updatedMemberMap.set(newMember.uid, newMember)
      this.setState({
        memberMap: updatedMemberMap
      })
      this.props.updateNumMembers(this.state.memberMap.size)
    })

    let realtimeChildChanged = this.realtime_database.ref(this.props.shareplayCode + '/members').on('child_changed', (snapshot) => {
      console.log('Realtime database child modified')
      let updatedMemberMap = this.state.memberMap
      let updatedMember = {
        email: snapshot.val().email,
        uid: snapshot.val().uid,
        username: snapshot.val().username,
        profilePicture: snapshot.val().profilePicture,
        access_token: snapshot.val().access_token,
        refresh_token: snapshot.val().refresh_token,
        owner: snapshot.val().uid === auth.currentUser.uid,
      }
      updatedMemberMap.set(updatedMember.uid, updatedMember)
      this.setState({
        memberMap: updatedMemberMap
      })
    })

    let realtimeChildRemoved = this.realtime_database.ref(this.props.shareplayCode + '/members').on('child_removed', (snapshot) => {
      console.log('Realtime database child deleted')
      let updatedMemberMap = this.state.memberMap
      updatedMemberMap.delete(snapshot.val().uid)
      this.setState({
        memberMap: updatedMemberMap
      })
      this.props.updateNumMembers(this.state.memberMap.size)
    })

    this.setState({
      memberSubscription: [realtimeChildAdded, realtimeChildChanged, realtimeChildRemoved]
    })
  }

  broadcasterInterval() {
    this.getUsersCurrentTrack(this.state.ownerAccessToken).catch((error) => {
      if(error === "Access Token Expired") {
        this.updateAccessToken(this.state.owner, this.state.ownerRefreshToken)
      }
    }).then((currentTrack) => {
      if(currentTrack) {
        if(currentTrack[2] !== this.state.songName) {
          this.realtime_database.ref(this.props.shareplayCode).update({
            albumArt: currentTrack[1],
            songName: currentTrack[2],
            artists: currentTrack[3],
            lastSongUpdated: Date.now()
          })
        }
      }
    })
  }

  setOwner(userID: string, accessToken: string) {
    this.realtime_database.ref(this.props.shareplayCode).update({
      owner: userID,
      ownerAccessToken: accessToken
    })
  }

  updateMembers() {

  }

  async getUsersCurrentTrack(userAccessToken: string) : Promise<[string,string,string,string,string]> {
    const currentTrackPromise = new Promise<[string,string,string,string, string]>((resolve,reject) => {
      var httpCurrentTrackRequest = new XMLHttpRequest();
      var urlCurrentTrack = 'https://api.spotify.com/v1/me/player/currently-playing';
      //open html request
      httpCurrentTrackRequest.open('GET', urlCurrentTrack, true);
      httpCurrentTrackRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken);
      httpCurrentTrackRequest.onreadystatechange = () => {
        if(httpCurrentTrackRequest.readyState === 4 && httpCurrentTrackRequest.responseText !== '') {
          let httpCurrentTrackResponse = JSON.parse(httpCurrentTrackRequest.responseText);
          //check for error
          if(httpCurrentTrackResponse.error && httpCurrentTrackResponse.error.status === 401) {
            reject("Access Token Expired")
          }
          if(httpCurrentTrackResponse.item) {
            let context : string
            if(httpCurrentTrackResponse.context) {
              context = httpCurrentTrackResponse.context.uri
            } else {
              context = ''
            }
            resolve([httpCurrentTrackResponse.item.uri, httpCurrentTrackResponse.item.album.images[0].url, httpCurrentTrackResponse.item.name, httpCurrentTrackResponse.item.artists, context])
          }
        }
      }
      httpCurrentTrackRequest.send()
    })
    return await currentTrackPromise
  }

  async readUri(context_uri: string, uri: string, userAccessToken: string) : Promise<string[]> {
    const readUriPromise = new Promise<string[]>((resolve,reject) => {
      let uriSplice = context_uri.split(':')
      if(context_uri.includes('playlist')) {
        this.getPlaylistSongs(uriSplice[uriSplice.length - 1], userAccessToken).then((songs) => {
          resolve(songs.slice(songs.indexOf(uri), songs.length - 1))
        })
      }
      else if(context_uri.includes('artist')) {
        this.getArtistSongs(uriSplice[uriSplice.length - 1], userAccessToken).then((songs) => {
          console.log('read uri checkpoint artist ' + songs )
          console.log(songs.indexOf(uri))
          resolve(songs.slice(songs.indexOf(uri), songs.length - 1))
        })
      }
      else if(context_uri.includes('album')) {
        this.getAlbumSongs(uriSplice[uriSplice.length - 1], userAccessToken).then((songs) => {
          resolve(songs.slice(songs.indexOf(uri), songs.length - 1))
        })
      }
      else {
        reject("Invalid Read Uri Call")
      }
    })
    return await readUriPromise
  }

  async getPlaylistSongs(spotifyId: string, userAccessToken: string) : Promise<string[]> {
    const playlistSongPromise = new Promise<string[]>((resolve,reject) => {
      var httpPlaylistSongRequest = new XMLHttpRequest();
      var urlPlaylistItems = 'https://api.spotify.com/v1/playlists/' + spotifyId + '/tracks'
      httpPlaylistSongRequest.open('GET', urlPlaylistItems, true);
      httpPlaylistSongRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken);
      httpPlaylistSongRequest.onreadystatechange = () => {
        if(httpPlaylistSongRequest.readyState === 4 && httpPlaylistSongRequest.responseText !== '') {
          let httpPlaylistSongResponse = JSON.parse(httpPlaylistSongRequest.responseText);
          //check for error
          if(httpPlaylistSongResponse.error && httpPlaylistSongResponse.error.status === 401) {
            reject("Access Token Expired")
          } else {
            let uris = httpPlaylistSongResponse.items.map((item: any) => {
              return item.track.uri
            })
            resolve(uris)
          }

        }
      }
      httpPlaylistSongRequest.send()
    })
    return await playlistSongPromise
  }

  async getArtistSongs(spotifyId: string, userAccessToken: string) : Promise<string[]> {
    const artistSongPromise = new Promise<string[]>((resolve,reject) => {
      var httpArtistSongRequest = new XMLHttpRequest();
      var urlArtistItems = 'https://api.spotify.com/v1/artists/' + spotifyId + '/top-tracks?market=US'
      httpArtistSongRequest.open('GET', urlArtistItems, true);
      httpArtistSongRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken);
      httpArtistSongRequest.onreadystatechange = () => {
        if(httpArtistSongRequest.readyState === 4 && httpArtistSongRequest.responseText !== '') {
          let httpArtistSongResponse = JSON.parse(httpArtistSongRequest.responseText);
          //check for error
          if(httpArtistSongResponse.error && httpArtistSongResponse.error.status === 401) {
            reject("Access Token Expired")
          } else {
            let uris = httpArtistSongResponse.tracks.map((item: any) => {
              return item.uri
            })
            console.log('artist checkpoint : ' + uris)
            resolve(uris)
          }

        }
      }
      httpArtistSongRequest.send()
    })
    return await artistSongPromise
  }

  async getAlbumSongs(spotifyId: string, userAccessToken: string) : Promise<string[]> {
    const albumSongPromise = new Promise<string[]>((resolve,reject) => {
      var httpalbumSongRequest = new XMLHttpRequest();
      var urlalbumItems = 'https://api.spotify.com/v1/albums/' + spotifyId + '/tracks';
      httpalbumSongRequest.open('GET', urlalbumItems, true);
      httpalbumSongRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken);
      httpalbumSongRequest.onreadystatechange = () => {
        if(httpalbumSongRequest.readyState === 4 && httpalbumSongRequest.responseText !== '') {
          let httpalbumSongResponse = JSON.parse(httpalbumSongRequest.responseText);
          //check for error
          if(httpalbumSongResponse.error && httpalbumSongResponse.error.status === 401) {
            reject("Access Token Expired")
          } else {
            let uris = httpalbumSongResponse.items.map((item: any) => {
              return item.uri
            })
            resolve(uris)
          }

        }
      }
      httpalbumSongRequest.send()
    })
    return await albumSongPromise
  }

  async refreshAccessToken(userRefreshToken: string) : Promise<[string,string]> {
    let refreshRequestPromise = new Promise<[string,string]>((resolve) => {
      var httpRefreshRequest = new XMLHttpRequest();
      var url = 'https://accounts.spotify.com/api/token'
      var refreshParams = 'grant_type=refresh_token&refresh_token='+userRefreshToken+'&client_id=f65ce3828b034109b715bd8be87d3719'
      httpRefreshRequest.open('POST', url, true);
      httpRefreshRequest.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      httpRefreshRequest.onreadystatechange = () => {
        if(httpRefreshRequest.readyState === 4 && httpRefreshRequest.responseText !== '') {
          let refreshResponse = JSON.parse(httpRefreshRequest.response)
          resolve([refreshResponse.access_token, refreshResponse.refresh_token])
        }
      }
      httpRefreshRequest.send(refreshParams)
    })
    return await refreshRequestPromise
  }

  async updateAccessToken(userID: string, userRefreshToken: string) {
    this.refreshAccessToken(userRefreshToken).then((updatedInfo) => {
      console.log(updatedInfo)
      this.realtime_database.ref(this.props.shareplayCode + '/members/' + userID).update({
        access_token: updatedInfo[0],
        refresh_token: updatedInfo[1],
        lastUpdated: Date.now()
      })
      db.collection('tokens').doc(userID).update({
        access_token: updatedInfo[0],
        refresh_token: updatedInfo[1],
        lastUpdated: Date.now()
      })
      if(userID === this.state.owner) {
        this.realtime_database.ref(this.props.shareplayCode).update({
          ownerAccessToken: updatedInfo[0],
          ownerRefreshToken: updatedInfo[1]
        })
      }
    })
  }

  async setUsersSong(userAccessToken: string, uri: string[]) {
    let setUserSongPromise = new Promise((resolve,reject) => {
      let params = {uris: uri}
      var httpSetSongRequest = new XMLHttpRequest()
      var url = 'https://api.spotify.com/v1/me/player/play'
      httpSetSongRequest.open('PUT', url ,true);
      httpSetSongRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken);
      httpSetSongRequest.onreadystatechange = () => {
        if(httpSetSongRequest.readyState === 4 && httpSetSongRequest.responseText !== '') {
          let httpSetSongResponse = JSON.parse(httpSetSongRequest.response)
          if(httpSetSongResponse.error) {
            reject(httpSetSongResponse.error)
          }
          else {
            resolve()
          }
        }
      }
      httpSetSongRequest.send(JSON.stringify(params))
    })
    return await setUserSongPromise
  }

  async pauseUsersSong(userAccessToken: string) {
    let pauseUserSongPromise = new Promise((resolve,reject) => {
      var httpPauseSongRequest = new XMLHttpRequest()
      var url = 'https://api.spotify.com/v1/me/player/pause'
      httpPauseSongRequest.open('PUT', url, true);
      httpPauseSongRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken)
      httpPauseSongRequest.onreadystatechange = () => {
        if(httpPauseSongRequest.readyState === 4 && httpPauseSongRequest.responseText !== '') {
          let httpPauseSongResponse = JSON.parse(httpPauseSongRequest.response)
          if(httpPauseSongResponse.error) {
            reject(httpPauseSongResponse.error)
          }
          else {
            resolve()
          }
        }
      }
      httpPauseSongRequest.send()
    })
    return await pauseUserSongPromise
  }

  async syncPlay(broadcasterAccessToken: string) {
    this.getUsersCurrentTrack(broadcasterAccessToken).then((currentTrack) => {
      if(currentTrack[4] !== '') {
        this.setState({
          shouldUseFakeContext: true
        })
        this.readUri(currentTrack[4], currentTrack[0], this.state.ownerAccessToken).then((songs) => {
          this.setState({
            fakeContext: songs
          })
          this.state.memberMap.forEach((member : Member) => {
            this.setUsersSong(member.access_token, songs).catch((error) => {
              if(error.status === 401) {
                this.updateAccessToken(member.email, member.refresh_token)
              } else {
                console.log('Error encountered when attempting syncPlay')
                console.log('Affected user: ' + member.email)
                console.log('Spotify response status code: ' + error.status)
              }
            })
          })
        })
      } else {

        if(this.state.shouldUseFakeContext) {
          if(currentTrack[0] === this.state.fakeContext[0]) {
            this.state.memberMap.forEach((member : Member) => {
              this.setUsersSong(member.access_token, this.state.fakeContext).catch((error) => {
                if(error.status === 401) {
                  this.updateAccessToken(member.email, member.refresh_token)
                } else {
                  console.log('Error encountered when attempting syncPlay')
                  console.log('Affected user: ' + member.email)
                  console.log('Spotify response status code: ' + error.status)
                }
              })
            })
          }
          else if(currentTrack[0] === this.state.fakeContext[1]) {
            //continue using fake context
            this.setState({
              fakeContext: this.state.fakeContext.slice(1)
            })
            if(this.state.fakeContext.length === 1) {
              this.setState({
                shouldUseFakeContext: false
              })
            }
            this.state.memberMap.forEach((member : Member) => {
              this.setUsersSong(member.access_token, this.state.fakeContext).catch((error) => {
                if(error.status === 401) {
                  this.updateAccessToken(member.email, member.refresh_token)
                } else {
                  console.log('Error encountered when attempting syncPlay')
                  console.log('Affected user: ' + member.email)
                  console.log('Spotify response status code: ' + error.status)
                }
              })
            })
          }
        }
        else {
          this.state.memberMap.forEach((member : Member) => {
            this.setUsersSong(member.access_token, [currentTrack[0]]).catch((error) => {
              if(error.status === 401) {
                this.updateAccessToken(member.email, member.refresh_token)
              } else {
                console.log('Error encountered when attempting syncPlay')
                console.log('Affected user: ' + member.email)
                console.log('Spotify response status code: ' + error.status)
              }
            })
          })
        }

      }
    })

  }

  async syncPause() {
    this.state.memberMap.forEach((member : Member) => {
      this.pauseUsersSong(member.access_token).catch((error) => {
        if(error.status === 401) {
          this.updateAccessToken(member.email, member.refresh_token)
        } else {
          console.log('Error encountered when attempting syncPause')
          console.log('Affected user: ' + member.email)
          console.log('Spotify response status code: ' + error.status)
        }
      })
    })
  }

  copyToClipboard() {
    try {
      navigator.clipboard.writeText(this.props.shareplayCode)
      this.setState({isToastOpen: true})
    }
    catch(error) {
      console.log(error)
      if(error === undefined) {
        this.setState({isToastOpen: true})
      }
    }
  }

  render() {
    return (
      <div>
        <MemberModal
          isMemberModalOpen={this.props.isMemberModalOpen}
          memberMap={this.state.memberMap}
          toggleMemberModal={this.props.toggleMemberModal}
          />
        <IonModal isOpen={this.props.isHelpModalOpen}>
        <IonHeader>
          <IonToolbar className='memberToolbarBackground'>
          </IonToolbar>
        </IonHeader>
          <IonContent className='helpModal'>
            <div className='helpRow'>
              <h2 className='helpText'><IonIcon icon={alertOutline} className='helpAlert'/>All devices need to be online and connected to spotify servers. If in doubt, try playing a song on each device.</h2>
            </div>
            <div className='helpRow'>
              <h2 className='helpText'><IonIcon icon={alertOutline} className='helpAlert'/>Ensure the host keeps the app open to synchronize playback.</h2>
            </div>
            <div className='helpRow'>
              <h2 className='helpText'><IonIcon icon={alertOutline} className='helpAlert'/>When a new song is detected to be playing on the host's spotify, the app will automatically restart the song and synchronize playback.</h2>
            </div>
            <div className='helpRow'>
              <h2 className='helpText'><IonIcon icon={alertOutline} className='helpAlert'/>If desired, playback can be manually synced and stopped using the 'Sync Play' and 'Sync Pause' buttons. To pause music on all devices, the 'Sync Pause' button MUST be used.</h2>
            </div>
            <div className='dismissContainer'>
              <IonButton onClick={this.props.toggleHelpModal} className='helpDismiss'>Dismiss</IonButton>
            </div>

          </IonContent>
        </IonModal>
        <IonToast cssClass='copiedToast' duration={200} isOpen={this.state.isToastOpen} onDidDismiss={() => {this.setState({isToastOpen: false})}} message='Code copied to Clipboard' position='bottom' />
        <div className='buttonsParent'>
          <IonButton fill='clear' onTouchStart={() => {this.setState({playHeld: true})}} onTouchEnd={() => {this.setState({playHeld: false})}} style={{backgroundColor: this.state.playHeld ? '#2f2f2f' : '#222222'}} className='syncPlay' onClick={() => {this.syncPlay(this.state.ownerAccessToken)}}>
            <h2 className='syncPlayLabel'>Sync Play</h2>
          </IonButton>
          <IonButton fill='clear' onTouchStart={() => {this.setState({pauseHeld: true})}} onTouchEnd={() => {this.setState({pauseHeld: false})}} style={{backgroundColor: this.state.pauseHeld ? '#2f2f2f' : '#222222'}} className='syncPause'onClick={() => {this.syncPause()}}>
            <h2 className='syncPauseLabel'>Sync Pause</h2>
          </IonButton>
        </div>
        {this.state.songName !== '' ? <div><img className='albumArt' src={this.state.albumArt} /><h2 className='songText'>{this.state.songName}</h2><h3 className='artistText'>{this.state.artists.map((Artist: { name: any; }, index: number) => <div key={index}>{Artist.name}{index === this.state.artists.length - 1 ? ' ' : ','}</div>)}</h3></div> : <h2 className='noContent'>No content detected</h2>}

        <div className='codeParent'>
          <h2 className='shareplayCode' onClick={() => {this.copyToClipboard()}}><span style={{color: '#99fdff'}}>#</span>{this.props.shareplayCode}</h2>
        </div>
      </div>
    )
  }
}

export default CreateShareplay
