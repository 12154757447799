import firebase from 'firebase';
var firebaseConfig = {
    apiKey: "AIzaSyA2GZnyzd-iNmWVCYVt7ce8EBeFsysUIZo",
    authDomain: "shareplay-jet.firebaseapp.com",
    databaseURL: "https://shareplay-jet.firebaseio.com",
    projectId: "shareplay-jet",
    storageBucket: "shareplay-jet.appspot.com",
    messagingSenderId: "88748315430",
    appId: "1:88748315430:web:13cf49d3a4a50fb97467e7",
    measurementId: "G-XF607B2LPJ"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export const auth = firebase.auth();
  export const db = firebase.firestore();
  export default firebase;
