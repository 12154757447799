import React from 'react'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonButton,
  IonContent,
  IonModal,
  IonInput,
  IonLabel,
  IonAvatar,
  IonItem,
  IonBadge
} from '@ionic/react';

import CreateShareplay from './CreateShareplay';
import firebase, {auth, db} from '../firebase.js';
import './Shareplay.css'
import ProfilePicture from '../assets/spacepicture.jpg'
import queryString from 'query-string';
import base64url from 'base64url';
import {personCircleOutline, send, pencil, checkmark, exitOutline, peopleOutline, helpCircleOutline} from 'ionicons/icons';

type MyState = {
  loggedIn: boolean; //is the user logged in (true / false)
  authPressed: string; //used to change color of 'Authenticate with Spotify' button.
  createPressed: string; //used to change color of 'Create shareplay' button
  joinPressed: string; //used to change color of 'Join shareplay' button
  uri: string; //used to store the uri to authenticate with spotify
  access_token: string; //user access token. passed to createShareplay and joinShareplay
  isAuthenticateModalOpen: boolean; //boolean controlling whether the 'Authenticate with Spotify' modal is open or closed
  email: string; //users email
  isShareplayActive: boolean; //keeps track of whether or not a user is in an active shareplay
  shareplayCode: string; //keeps track of the code of the Shareplay that the user is in
  profilePicture: string; //placeholder profile picture
  username: string; //the username the user has selected
  shareplayCodeJoin: string; //holds the code the user is typing in the 'Join Shareplay' input
  isProfileModalOpen: boolean; //boolean controlling whether the profile modal is open or not
  profReadOnly: boolean; //boolean to control whether the profile modal is read-only or not
  usernameChangeTemp: string; //string to keep track of what the user types into the 'username' input on the profile modal
  isMemberModalOpen: boolean; //controls whether the member modal is open or closed
  numMembers: number; //number of members in shareplay
  refresh_token: string; //refresh token of user
  isHelpModalOpen: boolean; //controls whether the help modal is open or closed
}

type MyProps = {
  location: any;
  history: any;
}

type Device = {
  id: string;
  is_active: boolean;
  is_private_session: boolean;
  name: string;
  type: string;
  volume_percent: number;
}

type Member = {
  email: string,
  access_token: string,
  refresh_token: string,
  profilePicture: string,
  username: string,
  uid: string
}

class Shareplay extends React.Component<MyProps, MyState> {
  inputProfRef: React.RefObject<HTMLIonInputElement>;
  access_code: string | string[];
  realtime_database = firebase.database()
  constructor(props: MyProps) {
    super(props);
    //Begin Set State
    this.state = {
      loggedIn: false,
      authPressed: '#99fdff',
      createPressed: '#222222',
      joinPressed: '#99fdff',
      uri: undefined,
      access_token: '',
      isAuthenticateModalOpen: false,
      email: '',
      isShareplayActive: false,
      shareplayCode: '',
      profilePicture: '',
      username: '',
      shareplayCodeJoin: '',
      isProfileModalOpen: false,
      profReadOnly: true,
      usernameChangeTemp: '',
      isMemberModalOpen: false,
      numMembers: 0,
      refresh_token: '',
      isHelpModalOpen: false
    }


    //End Set State

    //Refs
    this.inputProfRef = React.createRef();
    //End Refs

    auth.onAuthStateChanged(user => {
      //check to see if user is signed in
      if(user !== null) {
        //user signed in
        this.setState({email: user.email})
        db.collection('users').doc(this.state.email).onSnapshot((userDoc) => {
            this.setState({
              isShareplayActive: userDoc.data().inShareplay,
              shareplayCode: userDoc.data().shareplayCode,
              username: userDoc.data().username
            })
        })
        db.collection('tokens').doc(this.state.email).get().then((tokenDoc) => {
          if(tokenDoc.data().access_token !== '') {
            this.setState({
              access_token: tokenDoc.data().access_token,
              refresh_token: tokenDoc.data().refresh_token,
              isAuthenticateModalOpen: false
            })
          } else {
              if(!this.access_code) {
                this.setState({isAuthenticateModalOpen: true})
              } else {
                db.collection('verifiers').doc(this.state.email).get().then((verifierDocument) => {
                  if(verifierDocument.exists) {
                    this.getAccessToken(verifierDocument.data().verifier)
                  }
                })
              }
            }
          })
      }
    })

    //Begin Function Bindings

    this.setVerifierChallengeState = this.setVerifierChallengeState.bind(this);
    this.generateRandomString = this.generateRandomString.bind(this);
    this.pkceChallengeFromVerifier = this.pkceChallengeFromVerifier.bind(this);
    this.sha256 = this.sha256.bind(this);
    this.generateRandomState = this.generateRandomState.bind(this);
    this.handleAuthenticate = this.handleAuthenticate.bind(this);
    this.generateShareplayCode = this.generateShareplayCode.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
    this.enterFunction = this.enterFunction.bind(this);
    this.leaveShareplay = this.leaveShareplay.bind(this);
    this.logOut = this.logOut.bind(this);
    this.toggleMemberModal = this.toggleMemberModal.bind(this);
    this.updateNumMembers = this.updateNumMembers.bind(this);
    this.toggleHelpModal = this.toggleHelpModal.bind(this);
    this.checkAccessToken = this.checkAccessToken.bind(this);
    this.getAvailableDevices = this.getAvailableDevices.bind(this);
    //End Function Bindings


  /*  if(token) {
      spotifyApi.setAccessToken(token);
    }*/
    //Get Hash paramters
    const params = queryString.parse(this.props.location.search)
    //Store access code to give to Spotify Auth Endpoint
    this.access_code = params.code
    //Check to see if we have an access code
    if(this.access_code) {
      //Check firebase for our verifier
    }
  }

  componentDidMount() {
    //grab firebase information
    if(auth.currentUser) {
      //we are logged in
          db.collection('users').doc(auth.currentUser.email).get().then((document) => {
            this.setState({
              username: this.state.username,
              email: this.state.email
            })
          })
      }
  }

  checkAccessToken(userAccessToken: string) { //ensures our access token is updated

  }

  async getAvailableDevices(userAccessToken: string): Promise<Device[]> {
    const getAvailableDevicesPromise = new Promise<Device[]>((resolve, reject) => {
      const httpDevicesRequest = new XMLHttpRequest();
      const urlDevices = 'https://api.spotify.com/v1/me/player/devices'
      httpDevicesRequest.open('GET', urlDevices, true);
      httpDevicesRequest.setRequestHeader('Authorization', 'Bearer ' + userAccessToken)
      httpDevicesRequest.onreadystatechange = () => {
        if(httpDevicesRequest.readyState === 4 && httpDevicesRequest.responseText !== '') {
          const httpDevicesResponse = JSON.parse(httpDevicesRequest.responseText)
          if(httpDevicesResponse.error) {
            reject(httpDevicesResponse.error)
          } else {
            resolve(httpDevicesResponse.devices)
          }
        }
      }
      httpDevicesRequest.send()
    })
    return await getAvailableDevicesPromise
  }

  updateNumMembers(x: number) {
    this.setState({
      numMembers: x
    })
  }


 // BEGIN PKCE AUTHENTICATION FUNCTIONS
  generateRandomString(length: number) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  generateRandomState(length: number) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  // Calculate the SHA256 hash of the input text.
  // Returns a promise that resolves to an ArrayBuffer
  sha256(plain : string) {
      const encoder = new TextEncoder();
      const data = encoder.encode(plain);
      return window.crypto.subtle.digest('SHA-256', data);
  }

  // Base64-urlencodes the input string
  base64urlencode(str : any) {
      // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
      // btoa accepts chars only within ascii 0-255 and base64 encodes them.
      // Then convert the base64 encoded to base64url encoded
      //   (replace + with -, replace / with _, trim trailing =)
      return base64url.encode(str)
  }

  // Return the base64-urlencoded sha256 hash for the PKCE challenge
  async pkceChallengeFromVerifier(v : string) {
      let hashed = await this.sha256(v);
      return this.base64urlencode(hashed);
  }

  //Function to create a new verifier to authenticate user with Spotify
  async createVerifier() : Promise<[string, string, string]>  {
    console.log('Calling Create Verifier')
    let newVerifier = this.generateRandomString(128);
    let reqState = this.generateRandomState(128)
    let challenge = await this.pkceChallengeFromVerifier(newVerifier)
    console.log('Verifier: ' + newVerifier)
    console.log('Challenge: ' + challenge)
    console.log('State: ' + reqState)

    return [newVerifier, challenge, reqState]
  }

  //Function to store a user's verifier/challenge/state from Firebase Firestore
  async setVerifierChallengeState(verifier: string, challenge: string, reqstate: string) : Promise<string> {
    console.log('Running setVerifierChallengeState')
    let completion = await db.collection('verifiers').doc(this.state.email).set({
      verifier: verifier,
      challenge: challenge,
      reqstate: reqstate
    }).then(() => {
      return true
    })
    if(completion === true) {
      return 'Success'
    } else {
      return 'False'
    }

  }

  //Function to create verifier, store it, build URI, and then redirect user to URI
  async handleAuthenticate() {
    let information = await this.createVerifier()
    let completion = await this.setVerifierChallengeState(information[0], information[1], information[2])
    if(completion === 'Success') {
      let base = 'https://accounts.spotify.com/authorize'
      let clientid = ['client_id=','f65ce3828b034109b715bd8be87d3719']
      let response_type = ['response_type=','code']

      //let redirect_uri = ['redirect_uri=','https://shareplay.dev/shareplay']
      let redirect_uri = ['redirect_uri=','https://shareplay.dev/shareplay']
      let code_challenge_method = ['code_challenge_method=','S256']
      let code_challenge = ['code_challenge=', information[1]]
      let scope = ['scope=','streaming user-read-currently-playing user-read-playback-state']
      let reqstate = ['state=', information[2]]
      let final = base + '?' + response_type[0] + response_type[1] + '&' + clientid[0] + clientid[1] + '&' + redirect_uri[0] + redirect_uri[1] + '&' + reqstate[0] + reqstate[1] + '&' + code_challenge[0] + code_challenge[1] + '&' + code_challenge_method[0] + code_challenge_method[1] + '&' + scope[0] + scope[1]
      this.setState({uri: final})
      console.log(final)
      window.location.replace(this.state.uri)
    }
  }

  getAccessToken(verifier : string) {
    //Compose POST HTML request
    var httpAccessTokenRequest = new XMLHttpRequest();
    var url = 'https://accounts.spotify.com/api/token'
    //var params = 'client_id=f65ce3828b034109b715bd8be87d3719&grant_type=authorization_code&code=' + this.access_code + '&redirect_uri=https://shareplay.dev/shareplay&code_verifier='+verifier
    var params = 'client_id=f65ce3828b034109b715bd8be87d3719&grant_type=authorization_code&code=' + this.access_code + '&redirect_uri=https://shareplay.dev/shareplay&code_verifier='+verifier
    httpAccessTokenRequest.open('POST', url ,true);
    httpAccessTokenRequest.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    httpAccessTokenRequest.onreadystatechange = () => {
      if(httpAccessTokenRequest.readyState === 4 && httpAccessTokenRequest.responseText !== '') {
        let accessTokenResponse = JSON.parse(httpAccessTokenRequest.responseText)
        console.log(accessTokenResponse)
        if(accessTokenResponse.access_token && accessTokenResponse.refresh_token) {
          db.collection('tokens').doc(auth.currentUser.email).update({
            access_token: accessTokenResponse.access_token,
            refresh_token: accessTokenResponse.refresh_token,
            lastUpdated: Date.now()
          })

          this.setState({
            access_token: accessTokenResponse.access_token,
            refresh_token: accessTokenResponse.refresh_token
          })
        }
      }
    }
    httpAccessTokenRequest.send(params)
  }
  // END PKCE AUTHENTICATION FUNCTIONS

  async createShareplay() {

    await this.generateShareplayCode().then((result) => {
      this.realtime_database.ref(this.state.shareplayCode).set({
        isActive: true,
        shareplayCode: this.state.shareplayCode,
        ownerAccessToken: this.state.access_token,
        ownerRefreshToken: this.state.refresh_token,
        owner: auth.currentUser.uid,
        memberSize: 1,
        songName: '',
        albumArt: '',
        artists: '',
        broadcaster: auth.currentUser.uid
      }).then(() => {
        this.realtime_database.ref(this.state.shareplayCode + '/members/' + auth.currentUser.uid).set({
          email: this.state.email,
          uid: auth.currentUser.uid,
          profilePicture: this.state.profilePicture,
          username: this.state.username,
          access_token: this.state.access_token,
          refresh_token: this.state.refresh_token
        })
      }).then(() => {
        db.collection('users').doc(this.state.email).update({
          inShareplay: true,
          shareplayCode: this.state.shareplayCode
        })
      })
    })
      /*db.collection('shareplays').doc(this.state.shareplayCode).set({
        isActive: true,
        shareplayCode: this.state.shareplayCode,
        ownerAccessToken: this.state.access_token,
        ownerRefreshToken: this.state.refresh_token,
        owner: this.state.email,
        memberSize: 1,
        songName: '',
        albumArt: '',
        artists: '',
        broadcaster: this.state.email
      }).then(() => {
        db.collection('shareplays').doc(this.state.shareplayCode).collection('members').doc(this.state.email).set({
          email: this.state.email,
          profilePicture: this.state.profilePicture,
          username: this.state.username,
          access_token: this.state.access_token,
          refresh_token: this.state.refresh_token,
        })
      })
      db.collection('users').doc(this.state.email).update({
        inShareplay: true,
        shareplayCode: this.state.shareplayCode
      })
    })*/


  }

  async joinShareplay(code: string) {
    console.log(code)
    this.setState({shareplayCode: code})
    this.realtime_database.ref(code).once('value', shareplaySnapshot => {
      if(shareplaySnapshot.exists) {
        this.realtime_database.ref(code).update({
          memberSize: firebase.database.ServerValue.increment(1)
        })
        this.realtime_database.ref(code + '/members/' + auth.currentUser.uid).set({
          email: this.state.email,
          profilePicture: this.state.profilePicture,
          username: this.state.username,
          access_token: this.state.access_token,
          refresh_token: this.state.refresh_token,
          uid: auth.currentUser.uid
        })
        db.collection('users').doc(this.state.email).update({
          inShareplay: true,
          shareplayCode: code
        })
      }
    })
  }

  async generateShareplayCode()  {
    return new Promise((resolve, reject) => {
      let code = Math.random().toString(36).substr(2,7).toUpperCase();

      db.collection('codes').doc(code).get().then((doc) => {
        if(!doc.exists) {
          db.collection('codes').doc(code).set({
            owner: this.state.email,
            members: [this.state.email]
          })
          this.setState({shareplayCode: code})
          resolve()
        } else {
          this.generateShareplayCode()
        }
      })
    })
  }

  enterFunction(e : any) {
    if(e.keyCode === 13) {
      this.setState({profReadOnly: true})
      this.inputProfRef.current.setBlur()
      this.changeUsername()
    }
  }

  usernameEdit() {
    if(this.state.profReadOnly) {
      this.setState({profReadOnly: !this.state.profReadOnly})
    } else {
      this.changeUsername()
        this.setState({profReadOnly: !this.state.profReadOnly})
    }


  }

  changeUsername() {
    if(this.state.usernameChangeTemp !== '') {
      db.collection('users').doc(this.state.email).update({
        username: this.state.usernameChangeTemp
      })
      if(this.state.isShareplayActive) {
        db.collection('shareplays').doc(this.state.shareplayCode).collection('members').doc(this.state.email).update({
          username: this.state.usernameChangeTemp
        })
      }
      this.setState({username: this.state.usernameChangeTemp})
    }
  }

  leaveShareplay() {
    //Function goal: remove a user from a Shareplay and ensure the group remains functional.
    this.realtime_database.ref(this.state.shareplayCode).once('value', shareplaySnapshot => {
      if(shareplaySnapshot.val().owner === auth.currentUser.uid) {
        //the user is the owner of the group. check membership to see if group should be deleted or ownership passed
        if(shareplaySnapshot.val().memberSize > 1) {
          //other members of group, pass ownership
          let members : Member[] = []
          this.realtime_database.ref(this.state.shareplayCode + '/members/').once('value', memberSnapshot => {
            memberSnapshot.forEach(member => {
              members.push(member.val())
            })
          })
          if(members[0].uid !== auth.currentUser.uid) {
            this.realtime_database.ref(this.state.shareplayCode).update({
              ownerAccessToken: members[0].access_token,
              ownerRefreshToken: members[0].refresh_token,
              owner: members[0].uid,
              broadcaster: members[0].uid
            })
          } else {
            this.realtime_database.ref(this.state.shareplayCode).update({
              ownerAccessToken: members[1].access_token,
              ownerRefreshToken: members[1].refresh_token,
              owner: members[1].uid,
              broadcaster: members[1].uid
            })
          }
          this.decreaseMemberSize()
          this.removeMember(auth.currentUser.uid)
          this.updateUserProfileToNotBeInGroup(this.state.email)

        } else {
          //there is only one user in the group. need to delete the group
          this.realtime_database.ref(this.state.shareplayCode).remove()
          this.updateUserProfileToNotBeInGroup(this.state.email)
          db.collection('codes').doc(this.state.shareplayCode).delete()
        }
      } else {
        this.removeMember(auth.currentUser.uid)
        this.decreaseMemberSize()
        this.updateUserProfileToNotBeInGroup(this.state.email)
      }
    })
  }

  removeMember(userid : string) {
    this.realtime_database.ref(this.state.shareplayCode + '/members/' + userid).remove()
  }

  decreaseMemberSize() {
    this.realtime_database.ref(this.state.shareplayCode).update({
      memberSize: firebase.database.ServerValue.increment(-1)
    })
  }

  updateUserProfileToNotBeInGroup(email: string) {
    db.collection('users').doc(email).update({
      shareplayCode: '',
      inShareplay: false,
    })
  }

  toggleMemberModal() {
    this.setState({
      isMemberModalOpen: !this.state.isMemberModalOpen
    })
  }

  toggleHelpModal() {
    this.setState({
      isHelpModalOpen: !this.state.isHelpModalOpen
    })
  }

  logOut() {
    if(this.state.isShareplayActive) {
      this.leaveShareplay()
    }
    auth.signOut().then(() => {
      this.access_code = undefined
      this.setState({
        loggedIn: false,
        authPressed: '#99fdff',
        createPressed: '#222222',
        joinPressed: '#99fdff',
        uri: undefined,
        access_token: '',
        isAuthenticateModalOpen: false,
        email: '',
        isShareplayActive: false,
        shareplayCode: '',
        profilePicture: '',
        username: '',
        shareplayCodeJoin: '',
        isProfileModalOpen: false,
        profReadOnly: true,
        usernameChangeTemp: '',
        isMemberModalOpen: false,
        numMembers: 0,
        refresh_token: '',
        isHelpModalOpen: false
      })
      this.props.history.push('/landing')
    });
  }

  render() {
    return (
      <div>

      <IonPage>
        <IonHeader>
          <IonToolbar className='shareplayToolbar'>
          <IonButtons slot='start'>
            {this.state.isShareplayActive ?
              <IonButton fill='clear' onClick={() => {this.leaveShareplay()}}>
                <IonIcon className='leaveShareplay' icon={exitOutline} />
              </IonButton> : undefined}
          </IonButtons>
            <IonButtons slot='end'>
            {
              this.state.isShareplayActive ?
              <div>
                <IonButton onClick={() => {this.setState({isHelpModalOpen: !this.state.isHelpModalOpen})}} className='shareplayHelpButton'>
                  <IonIcon icon={helpCircleOutline} className='shareplayHelpIcon' />
                </IonButton>
                <IonButton onClick={() => {this.setState({isMemberModalOpen: !this.state.isMemberModalOpen})}} fill='clear'>
                  <IonBadge className='numMembersBadge' slot='start'>{this.state.numMembers}</IonBadge>
                  <IonIcon icon={peopleOutline} className='profileIcon'/>
                </IonButton>
                </div>
                :
                <IonButton onClick={() => {this.setState({isProfileModalOpen: !this.state.isProfileModalOpen})}} fill='clear'>
                  <IonIcon icon={personCircleOutline} className='profileIcon'/>
                </IonButton>
            }
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className='shareplayBackground' forceOverscroll={false}>

        <IonModal cssClass='authenticateModal' backdropDismiss={false} isOpen={this.state.isAuthenticateModalOpen}>
          <IonContent className='modalBackground'>
            <div>
              <h2 className='authDesc'>Please link your Shareplay account with Spotify</h2>
              <div onClick={() => {this.handleAuthenticate()}}>
                <div onTouchEnd={()=>{this.setState({authPressed: '#99fdff'})}} onTouchStart={() => {this.setState({authPressed: '#7f7880'})}} style={{backgroundColor: this.state.authPressed}} className='authButton'>
                  <h2 className='authText'>Authenticate Spotify</h2>
                </div>
              </div>
            </div>
          </IonContent>
        </IonModal>

        <IonModal cssClass='profileModal' isOpen={this.state.isProfileModalOpen}>
        <IonHeader>
          <IonToolbar className='profToolbar'>
            <IonButtons slot='start'>
              <IonButton onClick={() => {this.setState({isProfileModalOpen: false})}}className='profClose'>
                Close
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton onClick={() => {this.logOut()}} className='profLogOut'>
                Log Out
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent forceOverscroll={false} className='modalBackground'>
            <div className='container'>
              <IonAvatar className='profPagePhoto'>
                <img src={ProfilePicture} alt="" />
              </IonAvatar>
              <h2 className='profPageTitle'>{this.state.email}</h2>
              <div className='usernameContainer'>
                <IonLabel className='usernameLabel' position='floating'>Username</IonLabel>
                <div className='usernameFloat'>
                <IonItem lines='none' className={this.state.profReadOnly ? 'usernameItem' : 'usernameItemAlt'}>
                  <IonInput placeholder={this.state.username} ref={this.inputProfRef} onKeyUp={(e) => {this.enterFunction(e)}} onIonChange={(e) => {this.setState({usernameChangeTemp: (e.target as HTMLInputElement).value})}} autofocus={true} enterkeyhint='done' inputmode='text' readonly={this.state.profReadOnly} className='usernameInput'/>
                </IonItem>
                <IonButton onClick={() => {this.usernameEdit()}} className='usernameButton' fill='clear'>
                  <IonIcon className='usernameIcon' slot='end' icon={this.state.profReadOnly ? pencil : checkmark}/>
                </IonButton>
                </div>
              </div>
            </div>
          </IonContent>
        </IonModal>

          {this.state.isShareplayActive ? <CreateShareplay toggleHelpModal={this.toggleHelpModal} isHelpModalOpen={this.state.isHelpModalOpen} updateNumMembers={this.updateNumMembers} toggleMemberModal={this.toggleMemberModal} isMemberModalOpen={this.state.isMemberModalOpen} email={this.state.email} shareplayCode={this.state.shareplayCode} /> : <div>

            <div className='createParent'>
              {/*<h2 className='createLabel'>Create a Shareplay</h2>*/}
              <IonButton fill='clear' onClick={() => {this.createShareplay()}} onTouchEnd={()=>{this.setState({createPressed: '#222222'})}} onTouchStart={() => {this.setState({createPressed: '#555555'})}} className='createButtonParent' style={{backgroundColor: this.state.createPressed}}>
              <span className='createText'>Create a Shareplay</span>
              </IonButton>
            </div>

            <div className='joinParent'>
              {/*<h2 className='joinLabel'>Join a Shareplay</h2>*/}
              <div className='joinCodeParent'>
                <h2 className='joinCodeLabel'>#</h2>
                <IonInput onIonChange={(e) => {this.setState({shareplayCodeJoin: (e.target as HTMLInputElement).value.toUpperCase()})}} placeholder='Join a Shareplay' className='joinCodeInput'/>
                <IonIcon onClick={() => {this.joinShareplay(this.state.shareplayCodeJoin)}} className='joinIcon' icon={send}/>
              </div>
            </div>

            </div>}

        </IonContent>
      </IonPage>
      </div>
    )
  }
}

export default Shareplay
